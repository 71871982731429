.redTheme{
    background: rgb(61,30,30);
    background: linear-gradient(0deg, rgba(47,23,23,1) 0%, rgba(116,58,58,1) 50%, rgba(168,84,84,1) 100%) !important
}
.redThemePreview{
    background: rgb(61,30,30);
    background: linear-gradient(315deg, rgba(47,23,23,1) 0%, rgba(47,23,23,1) 49%,rgba(168,84,84,1) 50%, rgba(168,84,84,1) 100%) !important
}
.redThemeDarkest{
    background: rgb(22, 11, 11);
    fill: rgb(32, 16, 16);
}
.redThemeDark{
    background: rgb(61,30,30) !important;
    fill: rgb(61,30,30)!important;
}
.redThemeBorder{
    border:2px solid rgba(168,84,84,1)!important;
}
.redThemeMed{
    background: rgba(116,58,58,1)!important;
    fill: rgba(116,58,58,1);
}
.redThemeLight{
    background: rgba(168,84,84,1)!important;
    fill: rgba(168,84,84,1);
}


.greenTheme{
    background: rgb(38,71,38);
    background: linear-gradient(0deg, rgba(14,33,28,1) 0%, rgba(57,112,93,1) 50%, rgba(118,185,172,1) 100%)
}
.greenThemePreview{
    background: rgb(38,71,38);
    background: linear-gradient(315deg, rgba(14,33,28,1) 0%,rgba(14,33,28,1) 49%, rgba(118,185,172,1) 50%, rgba(118,185,172,1) 100%)
}
.greenThemeDarkest{
    background: #091411;
    fill: rgba(14,33,28,1);
}
.greenThemeDark{
    background: rgba(14,33,28,1) !important;
    fill: rgba(14,33,28,1)!important;
}
.greenThemeBorder{
    border:2px solid rgba(118,185,172,1)!important;
}
.greenThemeMed{
    background: rgba(57,112,93,1)!important;
    fill: rgba(57,112,93,1);
}
.greenThemeLight{
    background: rgba(118,185,172,1)!important;
    fill: rgba(118,185,172,1);
}


.blueTheme{
    background: rgb(30,35,61);
    background: linear-gradient(0deg, rgba(20,23,56,1) 0%, rgba(44,49,120,1) 50%, rgba(64,66,173,1) 100%)
}
.blueThemePreview{
    background: rgb(30,35,61);
    background: linear-gradient(315deg, rgba(20,23,56,1) 0%, rgba(20,23,56,1) 49%, rgba(64,66,173,1) 50%, rgba(64,66,173,1) 100%)
}
.blueThemeDarkest{
    background: #090914 !important;
    fill: rgb(14, 14, 33) !important;
}
.blueThemeDark{
    background: rgb(14, 14, 33) !important;
    fill: rgb(14, 15, 33)!important;
}
.blueThemeBorder{
    border:2px solid rgb(119, 118, 185)!important;
}
.blueThemeMed{
    background: rgb(58, 57, 112)!important;
    fill: rgb(58, 57, 112);
}
.blueThemeLight{
    background: rgb(122, 118, 185)!important;
    fill: rgb(125, 118, 185);
}


.purpleTheme{
    background: #341e3d;
    background: linear-gradient(0deg, rgba(52,30,61,1) 0%, rgba(107,62,124,1) 50%, rgba(145,88,176,1) 100%)
}
.purpleThemePreview{
    background: #341e3d;
    background: linear-gradient(315deg, rgba(52,30,61,1) 0%, rgba(52,30,61,1) 49%, rgba(145,88,176,1) 50%, rgba(145,88,176,1) 100%)
}
.purpleThemeDarkest{
    background: #120914 !important;
    fill: rgb(31, 14, 33) !important;
}
.purpleThemeDark{
    background: rgb(30, 14, 33) !important;
    fill: rgb(31, 14, 33)!important;
}
.purpleThemeBorder{
    border:2px solid rgb(171, 118, 185)!important;
}
.purpleThemeMed{
    background: rgb(96, 57, 112)!important;
    fill: rgb(96, 57, 112);
}
.purpleThemeLight{
    background: rgb(171, 118, 185)!important;
    fill: rgb(171, 118, 185);
}


.pinkTheme{
    background: #3d1e3b;
    background: linear-gradient(0deg, rgb(59, 29, 58) 0%, rgb(156, 78, 146)50%, rgb(201, 101, 187)100%)
}
.pinkThemePreview{
    background: #3d1e3b;
    background: linear-gradient(315deg, rgb(59, 29, 58) 0%,rgb(59, 29, 58) 49%, rgb(201, 101, 187)50%, rgb(201, 101, 187)100%)
}
.pinkThemeDarkest{
    background: #140913 !important;
    fill: rgb(33, 14, 33) !important;
}
.pinkThemeDark{
    background: rgb(33, 14, 30) !important;
    fill: rgb(33, 14, 30)!important;
}
.pinkThemeBorder{
    border:2px solid rgb(185, 118, 179)!important;
}
.pinkThemeMed{
    background: rgb(112, 57, 105)!important;
    fill: rgb(112, 57, 105);
}
.pinkThemeLight{
    background: rgb(185, 118, 179)!important;
    fill: rgb(185, 118, 174);
}


.yellowTheme{
    background: #3d381e;
    background: linear-gradient(0deg, rgb(59, 57, 29) 0%, rgb(155, 156, 78)50%, rgb(201, 194, 101)100%)
}
.yellowThemePreview{
    background: #3d381e;
    background: linear-gradient(315deg, rgb(59, 57, 29) 0%,rgb(59, 57, 29) 49%, rgb(201, 194, 101)50%, rgb(201, 194, 101)100%)
}
.yellowThemeDarkest{
    background: #141309 !important;
    fill: rgb(33, 33, 14) !important;
}
.yellowThemeDark{
    background: rgb(33, 33, 14) !important;
    fill: rgb(33, 33, 14)!important;
}
.yellowThemeBorder{
    border:2px solid rgb(185, 178, 118)!important;
}
.yellowThemeMed{
    background: rgb(112, 106, 57)!important;
    fill: rgb(112, 111, 57);
}
.yellowThemeLight{
    background: rgb(185, 180, 118)!important;
    fill: rgb(185, 180, 118);
}


.grayTheme{
    background: #363636;
    background: linear-gradient(0deg, rgb(51, 51, 51) 0%, rgb(155, 155, 155)50%, rgb(194, 194, 194)100%)
}
.grayThemePreview{
    background: #363636;
    background: linear-gradient(315deg, rgb(51, 51, 51) 0%,rgb(51, 51, 51) 49%, rgb(194, 194, 194)50%, rgb(194, 194, 194)100%)
}
.grayThemeDarkest{
    background: #131313 !important;
    fill: rgb(31, 31, 31) !important;
}
.grayThemeDark{
    background: rgb(31, 31, 31) !important;
    fill: rgb(32, 32, 32)!important;
}
.grayThemeBorder{
    border:2px solid rgb(185, 185, 185)!important;
}
.grayThemeMed{
    background: rgb(107, 107, 107)!important;
    fill: rgb(116, 116, 116);
}
.grayThemeLight{
    background: rgb(180, 180, 180)!important;
    fill: rgb(190, 190, 190);
}

.rwbTheme{
    background: rgb(30,35,61);
    background: linear-gradient(0deg, rgba(20,23,56,1) 0%,  rgb(173, 64, 64) 100%)
}
.rwbThemePreview{
    background: rgb(30,35,61);
    background: linear-gradient(315deg, rgba(20,23,56,1) 0%,rgba(20,23,56,1) 49%,rgb(173, 64, 64) 50%,  rgb(173, 64, 64) 100%)
}
.rwbThemeDarkest{
    background: #090914 !important;
    fill: rgb(14, 14, 33) !important;
}
.rwbThemeDark{
    background: rgb(14, 14, 33) !important;
    fill: rgb(14, 15, 33)!important;
}
.rwbThemeBorder{
    border:2px solid rgb(185, 185, 185)!important;
}
.rwbThemeMed{
    background: rgb(58, 57, 112)!important;
    fill: rgb(58, 57, 112);
}
.rwbThemeLight{
    background: rgba(168,84,84,1)!important;
    fill: rgba(168,84,84,1);
}

.p-0{
    padding: 0 !important;
}

.justify-center{
    display: flex;
    justify-content: center !important;
}

@media only screen and (max-width: 600px) {
.center-modal {
    width: '100%';
    max-width: '650px';
    max-height: '100%';
    top: '20%';
    left: '5%';
    color: aliceblue ;
    left: '15%' !important
}
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
.center-modal {
    width: '100%';
    max-width: '650px';
    max-height: '100%';
    top: '30%' !important;
    left: '25%' !important;
    color: aliceblue ;
    left: '15%' !important
}
}

.paperBackground {
    background-color: '#F7F7F7' ;
    color:'#AEB0B4'
}