.redTheme{
    background: rgb(61,30,30) !important;
    background: linear-gradient(0deg, rgba(47,23,23,1) 0%, rgba(116,58,58,1) 50%, rgba(168,84,84,1) 100%) !important
}
.redThemeDarkest{
    background: rgb(22, 11, 11) !important;
    fill: rgb(32, 16, 16) !important;
}
.redThemeDark{
    background: rgb(61,30,30) !important;
    fill: rgb(61,30,30)!important;
}
.redThemeBorder{
    border:2px solid rgba(168,84,84,1)!important;
}
.redThemeMed{
    background: rgba(116,58,58,1)!important;
    fill: rgba(116,58,58,1);
}
.redThemeLight{
    background: rgba(168,84,84,1)!important;
    fill: rgba(168,84,84,1);
}
.greenThemeDarkest{
    background: #091411 !important;
    fill: rgba(14,33,28,1);
}
.greenThemeDark{
    background: rgba(14,33,28,1) !important;
    fill: rgba(14,33,28,1)!important;
}
.greenThemeBorder{
    border:2px solid rgba(118,185,172,1)!important;
}
.greenThemeMed{
    background: rgba(57,112,93,1)!important;
    fill: rgba(57,112,93,1);
}
.greenThemeLight{
    background: rgba(118,185,172,1)!important;
    fill: rgba(118,185,172,1);
}
.greenTheme{
    background: linear-gradient(0deg, rgba(14,33,28,1) 0%, rgba(57,112,93,1) 50%, rgba(118,185,172,1) 100%)
}
.lightBlueTheme{
    background: rgb(30,60,61);
    background: linear-gradient(0deg, rgba(23,47,46,1) 0%, rgba(58,116,113,1) 50%, rgba(84,168,161,1) 100%)
}
.darkBlueTheme{
    background: rgb(30,35,61);
    background: linear-gradient(0deg, rgba(20,23,56,1) 0%, rgba(44,49,120,1) 50%, rgba(64,66,173,1) 100%)

}
.purpleTheme{
    background: rgb(52,30,61);
    background: linear-gradient(0deg, rgba(52,30,61,1) 0%, rgba(107,62,124,1) 50%, rgba(145,88,176,1) 100%)

}